var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"ubah-pengajuan-master-program"}},[_c('b-card',{attrs:{"header":"Ubah Master Proses Bisnis / Program","header-tag":"h4","header-class":"border-bottom mb-2"}},[(_vm.alert)?[_c('b-alert',{attrs:{"variant":_vm.alertDetail.variant,"show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.alertDetail.title)+" ")]),(_vm.alertDetail.variant === 'danger' && _vm.alertDetail.errors !== '')?_c('div',{staticClass:"alert-body"},[(typeof _vm.alertDetail.errors === 'object')?_c('ul',_vm._l((_vm.alertDetail.errors),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "+_vm._s(item)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.alertDetail.text + ': ' + _vm.alertDetail.errors))])]):_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.alertDetail.text)+" ")])])]:_vm._e(),_c('validation-observer',{ref:"formBisnis"},[_c('form',{attrs:{"id":"editMasterBisnis"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Program sesuai dengan Renja'),expression:"'Nama Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Program","label-for":"vi-nama_program"}},[_c('validation-provider',{attrs:{"name":"Nama Program","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_program","required":"","name":"nama_program","placeholder":"Nama Program"},model:{value:(_vm.bisnisData.nama_program),callback:function ($$v) {_vm.$set(_vm.bisnisData, "nama_program", $$v)},expression:"bisnisData.nama_program"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sektor Pemerintahan","label-for":"vi-gov_sector_id"}},[_c('validation-provider',{attrs:{"name":"Sektor Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-gov_sector_id","required":"","name":"gov_sector_id","options":_vm.referenceData.ref_sektor_pemerintahan,"placeholder":"Sektor Pemerintahan","label":"nama"},on:{"input":_vm.getDaftarUrusan},model:{value:(_vm.gov_sector_id),callback:function ($$v) {_vm.gov_sector_id=$$v},expression:"gov_sector_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Urusan Pemerintahan","label-for":"vi-gov_affair_id"}},[_c('validation-provider',{attrs:{"name":"Urusan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"gov_affair_id",attrs:{"id":"vi-gov_affair_id","required":"","name":"gov_affair_id","options":_vm.daftarUrusan,"placeholder":"Urusan Pemerintahan","label":"nama"},on:{"input":_vm.getFungsiGov},model:{value:(_vm.gov_affair_id),callback:function ($$v) {_vm.gov_affair_id=$$v},expression:"gov_affair_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Fungsi Pemerintahan","label-for":"vi-gov_function"}},[_c('validation-provider',{attrs:{"name":"Fungsi Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.fungsi_and_sub)?_c('v-select',{ref:"gov_function",attrs:{"id":"vi-gov_function","required":"","name":"gov_function","options":_vm.daftarFungsi,"placeholder":"Fungsi Pemerintahan","label":"nama"},on:{"input":_vm.getSubFungsiGov},model:{value:(_vm.gov_function),callback:function ($$v) {_vm.gov_function=$$v},expression:"gov_function"}}):_vm._e(),(_vm.fungsi_and_sub)?_c('b-input-group',[(_vm.fungsi_and_sub)?_c('b-form-input',{attrs:{"id":"vi-gov_function","required":"","name":"gov_function","placeholder":"Fungsi Pemerintahan"},model:{value:(_vm.fungsi_pemerintahan_nama),callback:function ($$v) {_vm.fungsi_pemerintahan_nama=$$v},expression:"fungsi_pemerintahan_nama"}}):_vm._e(),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('cat')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sub Fungsi Pemerintahan","label-for":"vi-gov_sub_function"}},[_c('validation-provider',{attrs:{"name":"Sub Fungsi Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.sub_fungsi)?_c('v-select',{ref:"gov_sub_function",attrs:{"id":"vi-gov_sub_function","required":"","name":"gov_sub_function","options":_vm.daftarSubFungsi,"placeholder":"Sub Fungsi Pemerintahan","label":"nama"},on:{"input":_vm.setSubFungsiGov},model:{value:(_vm.gov_sub_function),callback:function ($$v) {_vm.gov_sub_function=$$v},expression:"gov_sub_function"}}):_vm._e(),(_vm.sub_fungsi)?_c('b-input-group',[(_vm.sub_fungsi)?_c('b-form-input',{attrs:{"id":"vi-gov_sub_function","required":"","name":"gov_sub_function","placeholder":"Sub Fungsi Pemerintahan"},model:{value:(_vm.sub_fungsi_pemerintahan_nama),callback:function ($$v) {_vm.sub_fungsi_pemerintahan_nama=$$v},expression:"sub_fungsi_pemerintahan_nama"}}):_vm._e(),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('sub')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'),expression:"'Merupakan urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Urusan","label-for":"vi-nama_urusan"}},[_c('validation-provider',{attrs:{"name":"Nama Urusan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_urusan","required":"","name":"nama_urusan","placeholder":"Nama Urusan"},model:{value:(_vm.bisnisData.nama_urusan),callback:function ($$v) {_vm.$set(_vm.bisnisData, "nama_urusan", $$v)},expression:"bisnisData.nama_urusan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Instansi yang mengajukan'),expression:"'Instansi yang mengajukan'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Instansi","label-for":"vi-instansi_id"}},[_c('b-form-input',{attrs:{"hidden":"","required":"","name":"instansi_id","placeholder":"Instansi"},model:{value:(_vm.userData.instansi_id),callback:function ($$v) {_vm.$set(_vm.userData, "instansi_id", $$v)},expression:"userData.instansi_id"}}),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Instansi"},model:{value:(_vm.userData.instansi_name),callback:function ($$v) {_vm.$set(_vm.userData, "instansi_name", $$v)},expression:"userData.instansi_name"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan uraian dari urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'),expression:"'Merupakan uraian dari urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Uraian Urusan","label-for":"vi-deskripsi_urusan"}},[_c('validation-provider',{attrs:{"name":"Uraian Urusan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi_urusan","rows":"4","required":"","name":"deskripsi_urusan","placeholder":"Uraian Urusan"},model:{value:(_vm.bisnisData.deskripsi_urusan),callback:function ($$v) {_vm.$set(_vm.bisnisData, "deskripsi_urusan", $$v)},expression:"bisnisData.deskripsi_urusan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]),_c('b-button',{staticClass:"ml-50",attrs:{"to":{ path: '/pengajuan/master/program' },"variant":"outline-secondary"}},[_vm._v(" Kembali ")])],1)],1)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }